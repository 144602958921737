<template>
    <div class="registrationWrapper">
        <div class="container">
            <div class="whiteBG-wrap">
                <div class="row">                
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                        <div class="regForm-wrap">
                            <h2>Group Contribution</h2>
                            <hr>
                            <contribution-form />
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
                        <contribution-side-info />
                    </div>                    
                </div>
                <span class="procecss-title">Employer Registration</span>
            </div>
        </div>        
    </div>
</template>
<script>
import ContributionForm from '../../components/Registration/ContributionInfo/ContributionForm.vue'
import ContributionSideInfo from '../../components/Registration/ContributionInfo/ContributionSideInfo.vue'
export default {
    name: 'ContributionInfo',
    components: {
        ContributionForm,
        ContributionSideInfo
    }
}
</script>
<style src="../../assets/css/group-registration.css"></style>