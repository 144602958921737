<template>
    <div>
        <form>
            <div class="form-group row">                
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-4 ">                    
                    <label class="custom-radio">Yes
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label>
                </div>   
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-4 ">                    
                    <label class="custom-radio">No
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label>
                </div>                
            </div>

            <h3>Group Defined Contribution Type</h3>
            <label class="custom-radio mb-4">Flat Dollar Amount
                <input type="radio" checked="checked" name="radio2">
                <span class="checkmark"></span>
            </label>
            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">            
                    <input type="text" class="reg-field" placeholder="eg: $10.00">      
                </div>
            </div>

            <label class="custom-radio mb-4">Percentage
                <input type="radio" checked="checked" name="radio2">
                <span class="checkmark"></span>
            </label>
            <div class="form-group row">
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4 ">     
                    <label>Member</label>       
                    <input type="text" class="reg-field" placeholder="eg: $10.00">        
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4 ">     
                    <label>Member + 1</label>       
                    <input type="text" class="reg-field" placeholder="eg: $10.00">        
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4 ">     
                    <label>Member + Child(ren)</label>       
                    <input type="text" class="reg-field" placeholder="eg: $10.00">        
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4 ">     
                    <label>Family</label>       
                    <input type="text" class="reg-field" placeholder="eg: $10.00">        
                </div>
            </div>
            
        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/payment-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/signature-verification" class="button-blue">Next</router-link>
                <router-link to="/registration/signature-verification" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PaymentForm'
}
</script>